import React from 'react'
import { useTranslation } from 'react-i18next'
import firebase from 'gatsby-plugin-firebase'
import { toast } from 'react-toastify'

const SignOutButton = () => {
  const { t } = useTranslation('signOut')

  const signOut = async () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        if (typeof window !== 'undefined') {
          window.location = '/'
        }
      })
      .catch((error) => {
        toast.error(t('error'))
      })
  }

  return (
    <button
      style={{
        background: 'white',
        color: 'blue',
        borderColor: 'white',
        fontFamily: 'Roboto',
        fontSize: '0.8em',
        cursor: 'pointer',
        border: 'none',
        marginLeft: '1em',
      }}
      onClick={signOut}
    >
      {t('button')}
    </button>
  )
}

export default SignOutButton
