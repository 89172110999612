import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { navigate, graphql } from 'gatsby'
import { useLazyQuery, useMutation } from '@apollo/client'
import { toast } from 'react-toastify'

import { FiSettings } from 'react-icons/fi'
import Layout from 'components/layout'
import SEO from 'components/seo'
import UserContext from 'context/user/UserContext'
import SignOutButton from 'components/SignOutButton'
import NewMeetingButton from 'components/Meeting/NewMeetingButton'
import { CHECK_IF_MEETING_EXISTS, CREATE_MEETING } from 'services/graphql'
import {
  FlexCenterWrapper,
  EnvelopeWrapper,
  EnvelopeWrapperImageTop,
  EnvelopeWrapperImageBottom,
  EnvelopeImageFlap,
  EnvelopeInnerCard,
  DashboardWrapper,
  Dashboard,
  FlexContainer,
  FlexCol,
  FlexRow,
  UserMessage,
  ButtonWrapper,
} from 'components/accountComponents'
import {
  JoinContainerAccount,
  JoinInputAccount,
  JoinButton,
} from 'components/Meeting/JoinMeetingForm/JoinMeetingFormComponents'
import Button from 'components/common/Button'
import LottieShape from 'components/animations/LottieShape'
import LoadingAnimation from 'components/animations/LoadingAnimation'
import envelopeBody from 'images/png/envelopeBody.png'
import envelopTopInside from 'images/png/envelopeTopInside.png'
import envelopeTopOutside from 'images/png/envelopeTopOutside.png'

const ERROR_MESSAGE = 'An error has ocurred.'
const INVALID_MEETING_MESSAGE =
  "Couldn't find the meeting you're trying to join."

const Account = ({ data, location }) => {
  const [isJoinLinkTextValid, setIsJoinLinkTextValid] = useState(true)
  const [joinLinkText, setJoinLinkText] = useState('')
  const [joinLink, setJoinLink] = useState(false)
  const { user, isAuthInitialized } = useContext(UserContext)
  const { t } = useTranslation('account')

  const siteTitle = data.site.siteMetadata.title

  const VALID_MEETING_ID_LENGTH = 12

  const [
    checkIfMeetingExists,
    {
      data: checkIfMeetingExistsData,
      loading: checkIfMeetingExistsLoading,
      error: checkIfMeetingExistsError,
    },
  ] = useLazyQuery(CHECK_IF_MEETING_EXISTS)

  const [
    createMeeting,
    {
      data: createMeetingData,
      loading: createMeetingLoading,
      error: createMeetingError,
    },
  ] = useMutation(CREATE_MEETING)

  useEffect(() => {
    if (typeof window !== 'undefined' && isAuthInitialized && !user) {
      navigate(process.env.GATSBY_SITE_SIGN_IN_PATH)
    }
  }, [user, isAuthInitialized])

  // redirect the user to new 3Dmeet meeting if valid, otherwise notify error using toast
  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      createMeetingData &&
      createMeetingData.createMeeting
    ) {
      window.location.assign(createMeetingData.createMeeting.hostLink)
    }
  }, [createMeetingData])

  // redirect the user to 3Dmeet URL if valid, otherwise notify error using toast
  useEffect(() => {
    if (
      checkIfMeetingExistsData &&
      checkIfMeetingExistsData.checkIfMeetingExists &&
      checkIfMeetingExistsData.checkIfMeetingExists.id
    ) {
      setIsJoinLinkTextValid(true)
      navigate(
        process.env.GATSBY_SITE_INVITE_PATH +
          checkIfMeetingExistsData.checkIfMeetingExists.id,
      )
    } else if (
      checkIfMeetingExistsData &&
      !checkIfMeetingExistsData.checkIfMeetingExists
    ) {
      setIsJoinLinkTextValid(false)
      notifyInvalidMeeting()
    }
  }, [checkIfMeetingExistsData])

  useEffect(() => {
    if (createMeetingError || checkIfMeetingExistsError) {
      notifyError()
    }
  }, [createMeetingError, checkIfMeetingExistsError])

  const notifyError = () => toast.error(ERROR_MESSAGE)

  const notifyInvalidMeeting = () => toast.dark(INVALID_MEETING_MESSAGE)

  const onClickNewMeeting = async () => {
    if (user && user.uid && !createMeetingLoading) {
      await createMeeting()
    }
  }

  const onClickJoinMeeting = async (meetingIdParam) => {
    if (!checkIfMeetingExistsLoading && !createMeetingLoading) {
      let meetingId = meetingIdParam

      // grab only the meetind id from input
      meetingId = meetingIdParam.slice(-VALID_MEETING_ID_LENGTH)

      await checkIfMeetingExists({
        variables: { meetingId },
      })

      // show toast for invalid meeting without recieving error on network request
      if (!isJoinLinkTextValid) {
        notifyInvalidMeeting()
      }
    }
  }

  const onChangeJoinLinkText = (e) => {
    setIsJoinLinkTextValid(true)
    setJoinLinkText(e.target.value)
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title={t('title')} />
      <h1>{t('heading')}</h1>
      {!isAuthInitialized ? <h3>{t('loading')}</h3> : null}
      {isAuthInitialized && user && (
        <FlexCenterWrapper>
          <EnvelopeWrapper>
            <EnvelopeWrapperImageTop src={envelopTopInside} />
            <EnvelopeInnerCard>
              <EnvelopeWrapperImageBottom src={envelopeBody} />
              <EnvelopeImageFlap src={envelopeTopOutside} />
              <DashboardWrapper>
                <Dashboard>
                  <UserMessage>
                    {t('welcome', { name: user.displayName })}
                    <SignOutButton />
                  </UserMessage>
                  <FlexContainer>
                    <FlexCol>
                      {t('joinMeetingLabel')}
                      <FlexRow>
                        <JoinContainerAccount>
                          <JoinInputAccount
                            type="text"
                            placeholder={t('joinMeetingInput')}
                            onClick={() => setJoinLink(true)}
                            onChange={(e) => onChangeJoinLinkText(e)}
                          />
                          {joinLink ? (
                            <JoinButton
                              disabled={
                                joinLinkText.length === 0 ||
                                createMeetingLoading ||
                                checkIfMeetingExistsLoading
                              }
                              onClick={() =>
                                onClickJoinMeeting(joinLinkText.trim())
                              }
                            >
                              {checkIfMeetingExistsLoading ? (
                                <LottieShape
                                  animationData={LoadingAnimation}
                                  size={31}
                                />
                              ) : (
                                t('joinMeetingButton')
                              )}
                            </JoinButton>
                          ) : null}
                        </JoinContainerAccount>
                      </FlexRow>
                    </FlexCol>
                    <FlexRow>
                      {t('startMeetingLabel')}
                      <ButtonWrapper>
                        <NewMeetingButton
                          onClickNewMeeting={onClickNewMeeting}
                          isLoading={createMeetingLoading}
                        />
                      </ButtonWrapper>
                    </FlexRow>
                    <FlexRow>
                      {t('manageAccountLabel')}
                      <ButtonWrapper>
                        <Button
                          text={t('manageAccountButton')}
                          onClick={() =>
                            navigate(
                              process.env.GATSBY_SITE_MANAGE_ACCOUNT_PATH,
                            )
                          }
                          element={
                            <FiSettings
                              size="1.4em"
                              style={{ marginRight: '1.15em' }}
                            />
                          }
                        />
                      </ButtonWrapper>
                    </FlexRow>
                  </FlexContainer>
                </Dashboard>
              </DashboardWrapper>
            </EnvelopeInnerCard>
          </EnvelopeWrapper>
        </FlexCenterWrapper>
      )}
    </Layout>
  )
}

export default Account

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
