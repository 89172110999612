import styled from '@emotion/styled'
import mq from 'styles/breakpoints'

export const FlexCenterWrapper = styled.div`
  display: flex;
  height: 65vh;
  justify-content: center;
  align-items: center;

  @media (max-height: 800px) and (min-width: 1600px) {
    padding-top: 10em;
  }
`

export const EnvelopeWrapper = styled.div`
  position: relative;
  display: inline-block;
  perspective: 1000px;
  transform-style: preserve-3d;
`

export const EnvelopeWrapperImageTop = styled.img`
  max-width: 100%;
  height: 10em;
  display: block;
  margin: 0 auto;
  user-drag: none;
  user-select: none;
  animation: fadeOutTop 0.9s linear forwards;

  @keyframes fadeOutTop {
    0% {
      visibility: hidden;
    }
    75% {
      visibility: hidden;
    }
    76.5% {
      visibility: visible;
    }
    100% {
      visibility: visible;
    }
  }

  ${mq['max']} {
    height: 15em;
  }

  ${mq['xxl']} {
    height: 10em;
  }
`

export const EnvelopeWrapperImageBottom = styled.img`
  max-width: 100%;
  height: 14.5em;
  display: block;
  margin: 0 auto;
  user-drag: none;
  user-select: none;
  animation: fadeOutBottom 0.9s linear forwards;

  @keyframes fadeOutBottom {
    0% {
      visibility: visible;
    }
    75% {
      visibility: visible;
    }
    100% {
      visibility: visible;
    }
  }

  ${mq['max']} {
    height: 26em;
  }

  ${mq['xxl']} {
    height: 14.5em;
  }
`

export const EnvelopeImageFlap = styled.img`
  max-width: 100%;
  position: absolute;
  top: 0.1em;
  height: 10em;
  display: block;
  margin: 0 auto;
  user-drag: none;
  user-select: none;
  animation: flap 0.9s linear forwards;

  @keyframes flap {
    0% {
      visibility: visible;
    }
    76% {
      visibility: hidden;
    }
    100% {
      visibility: hidden;
    }
  }

  ${mq['max']} {
    height: 15em;
  }

  ${mq['xxl']} {
    height: 10em;
  }
`

export const EnvelopeInnerCard = styled.div`
  transform-style: preserve-3d;
  perspective: 1000px;
  z-index: 0;
`

export const DashboardWrapper = styled.div`
  top: 0.1em;
  position: absolute;
  width: 100%;
  height: 325px;
  animation: insert-card 1s linear 1s forwards;
  display: flex;
  justify-content: center;
  visibility: hidden;

  @keyframes insert-card {
    0% {
      z-index: -1;
      visibility: visible;
    }
    49% {
      z-index: -1;
    }
    50% {
      transform: translateY(-100%) translateZ(0px);
      z-index: -1;
    }
    56% {
      z-index: 1;
    }
    99% {
      transform: translateY(-1%) translateZ(1px);
      z-index: 1;
    }
    100% {
      transform: translateY(-1%) translateZ(1px);
      z-index: 1;
      visibility: visible;
      margin-top: -4em;
    }
  }
`

export const Dashboard = styled.div`
  top: 0.1em;
  position: absolute;
  font-family: Roboto;
  height: 100%;
  width: 90%;
  background: white;
  color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
`

export const FlexContainer = styled.div`
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;

  ${mq['sm']} {
    margin-top: 0;
  }
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  ${mq['sm']} {
    align-items: center;
    margin-top: 0.5rem;
  }
`

export const FlexRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;

  ${mq['sm']} {
    display: flex;
    flex-direction: column;
    margin-bottom: 0rem;
  }
`

export const UserMessage = styled.p`
  margin-top: 0.5em;
  display: flex;
  justify-content: flex-start;
  font-size: 1.2rem;

  ${mq['sm']} {
    font-size: 1em;
  }

  ${mq['xsm']} {
    font-size: 1rem;
  }
`

export const ButtonWrapper = styled.div`
  margin-right: 2em;
`
